import { onMount } from "solid-js";
import { useWire } from "~/wire";
import { Named } from "@repo/logger";

/**
 * AuthRedirectTrigger is a component that processes OAuth redirect for FireBase. It should be rendered
 * at the root of the application within the WireContextProvider to handle the OAuth redirect.
 * @constructor
 */
export const AuthRedirectTrigger = () => {
  const wire = useWire();
  const logger = new Named(wire.dependencies.logger, "AuthRedirectTrigger");
  onMount(async () => {
    try {
      if (_LOG) logger.info("processing OAuth redirect");
      await wire.services.identity.processOAuthRedirect();
    } catch (error) {
      logger.error("OAuthRedirectErr", "error processing OAuthRedirect", error);
    }
  });
  return null;
};
