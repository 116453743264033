import type { Location } from "@solidjs/router";

/**
 * ENG-1500 - Parse URL for marketing parameters.
 */
export const parseUrlForMarketing = (
  location: Location<unknown>,
): { hasProperties: boolean; properties: Record<string, string | undefined> } => {
  const params = [
    "adgroup",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "network",
    "device",
    "placement",
  ];
  const properties: Record<string, string | undefined> = {};
  let hasProperties = false;

  for (const param of params) {
    if (location.query[param]) {
      properties[param] = location.query[param] as string;
      hasProperties = true;
    }
  }
  return {
    hasProperties,
    properties,
  };
};

/**
 * ENG-1500 - Parse URL for adgroup, used to setPersonProperties.
 */
export const parseUrlForAdgroup = (location: Location<unknown>) => {
  if (!location.query.adgroup) return;
  return location.query.adgroup as string;
};
