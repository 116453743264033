import { GrowthBook } from "@growthbook/growthbook";
// Coming from vite (app.config.ts)
declare const GROWTHBOOK_CLIENT_KEY: string;

export const initGrowthBookClient = () => {
  const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: GROWTHBOOK_CLIENT_KEY,
    enableDevMode: true,
  });
  return growthbook;
};
