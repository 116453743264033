export enum ExperimentKey {
  LimitAnonymousAccess = "limit-anonymous-access-10-20",
  GrowthbookVisualExperiments = "growthbook-visual-experiments",
}

// You can find all feature flags here: https://us.posthog.com/project/71364/feature_flags
export type Experiments = Partial<{
  [ExperimentKey.LimitAnonymousAccess]: {
    variant: "control" | "max-10" | "max-20";
    payload: undefined;
  };

  [ExperimentKey.GrowthbookVisualExperiments]: {
    variant: boolean;
    payload: undefined;
  };
}>;

// Mapped type from the source of truth Experiments, don't change it unless posthog has changed their api response
// Posthog returns this schema instead, we do some runtime work to convert from this to the Experiments schema
export type PosthogExperimentsResponse = {
  featureFlags: {
    [ExperimentKey in keyof Experiments]: NonNullable<Experiments[ExperimentKey]>["variant"];
  };
  featureFlagPayloads: {
    [ExperimentKey in keyof Experiments]: NonNullable<Experiments[ExperimentKey]>["payload"];
  };
};
